#login-container-manager {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Adjusts space between left and right sections */
  background: white;
  height: 100vh;
  width: 100vw;

  .container {
    display: flex;
    font-family: var(--font);
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    margin-left: 85px;
  }

  .heading {
    color: black;
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 10px;
    text-align: left;
  }

  .login-image {
    width: 80%;
    margin-bottom: 50px;
  }

  .login-image-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 1;
    max-width: 55%;
    min-width: 55%;
    height: auto;
    position: relative;
  }

  .login-group-image {
    width: 63%;
    /* height: auto; */
    max-height: 100vh;
    object-fit: cover;
    object-position: center;
    right: 0;
    bottom: 0;
    /* max-height: 100%; */
    position: absolute;
  }

  @media (max-width: 768px) {
    .heading {
      font-size: 40px;
    }
  }

  .form {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-field {
    margin-bottom: 20px;
    font-size: 14px;
    opacity: 0.7;
    width: 100%;
    letter-spacing: 0.5px;
    border: none;
    border-bottom: 1px solid black;
    padding: 8px 0;
    background-color: transparent;
    transition: border-bottom-color 0.3s ease-in-out;
  }

  .input-field:focus {
    outline: none;
    border-bottom-color: #6FB555;
  }

  .login-button {
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 30px 0;
    width: 100%;
    background-color: #6FB555;
    border-color: #6FB555;
    border-radius: 20px;
    padding: 10px;
    color: white;
    font-size: 16px;
    font-family: var(--font);
    transition: 0.3s ease-in-out;
  }

  .login-button:hover {
    background-color: #436c34;
  }

  .linkButton {
    color: #6FB555;
    font-size: 14px;
    background-color: inherit;
    border: none;
    padding: 5px;
    font-family: var(--font);
  }

  .linkButton:hover {
    color: #436c34;
    background-color: inherit;
  }

  .input-field.invalid-input {
    border-color: red;
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 8px;
    opacity: 0.5;
    height: 20px;
    overflow: hidden;
    letter-spacing: 1px;
  }

  #managerEmail_help {
    text-align: center;
    font-size: small;
    font-family: var(--font);
  }
}