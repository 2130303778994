.hr-line{
    border-top: 1px solid black;
    margin: 60px 0px;
}
.signature-hr{
    border-top: 1px solid black;
    margin: 20px 0px;
    width: 20%;
}
.manager-hr{
    border-top: 1px solid black;
    width: 100%;
}

.manager-sign{
    display: flex;
    justify-content: center;
}

.form-container input{
    border: none;
    border-bottom: 1px black solid;
}

.center-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    /* height: 100vh; Adjust the height as needed */
  }

.form-field{
    display: flex;
    font-family: var(--font);
    justify-content: space-between;
    padding-right: 20px;
    font-weight: 600;
}

.form-field input{
    border-radius: 0;
    width: 50%;
    height: 20px;
    text-align: center;
    font-size: small;
    font-weight: 400;
}
.small-button {
    font-size: 11px; /* Reduce the font size to make it smaller */
    padding: 3px 10px; /* Adjust padding to control the button size */
    /* Add any other styles as needed */
  }