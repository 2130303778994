/* Global variables, can be moved to another general CSS or root if you're using them elsewhere */
:root {
    --background: white;
    --font: 'Montserrat', sans-serif;
  }
  
  .body {
    display: flex;
    background-color: var(--background);
    padding: 5vh;
    font-family: var(--font);
    position: relative;
    min-height: 100vh;
    height: max-content;
  }
  
  .dashboard {
    margin: 0 auto;
    width: 90%;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .ant-select, .ant-input {
    font-family: var(--font);
    font-size: 15px;
  }
  
  @media (max-width: 768px) {
    .ant-select, .ant-input {
      font-size: 10px;
    }
  }
  
  @media (max-width: 500px) {
    .dashboard h3 {
      padding-top: 1vh;
      font-size: 17px;
    }
  
    .dashboard {
      padding: 0;
    }
  
    .body {
      padding: 2vh 0;
    }
  
    .input-fields {
      padding-bottom: 2vh;
    }
  }
  
  .home-logo {
    position: absolute;
    top: 3%;
    left: 3%;
    font-size: 5vh;
    color: #11686D;
  }
  
  .smalllogo-image {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 5vh;
  }
  
  .input-fields {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5vh;
  }
  
  .table-container {
    max-height: 1000px;
    overflow-x: auto;
    overflow-y: auto;
  }
  
  .ant-checkbox-group-item {
    font-family: var(--font);
    font-weight: 300;
    padding-right: 5px;
  }
  
  .ant-select-focused .ant-select-selector, .ant-input:focus {
    border-color: #11686D;
  }
  
  .ant-btn-primary {
    background-color: #11686D;
    border-color: #11686D;
  }
  
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #082f32;
    border-color: #082f32;
  }
  
  .ant-table-cell.wrap-text {
    white-space: normal !important;
    word-wrap: break-word !important;
    max-width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .ant-table-thead > tr > th {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
} */

/* To handle the overlapping shadows from the sticky header */
.ant-table-container::before {
  content: '';
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;  /* You can adjust this */
  z-index: 1;
}
/* .ant-input-search-button {
  background-color: #11686D;
}

.ant-input-search-button:hover {
  background-color: #082f32;
  
} */
