.manager-container {
    font-family: var(--font);
    background-color: white;
    min-height: 100vh;
    width: auto;
    position: relative;
}

.colored-background {

    .worldmap__figure-container {
        background-color: transparent !important;
    }
    .graph-container >div, .stat-card, 
    .home-container >div:first-child{
        background-color: #FFFFFF50 !important;
    }
}

.stats.colored-background {
    background: linear-gradient(180.18deg, #DEE7E2 9.19%, #76AFA5 81.9%, #397E79 99.84%);
}

.manager-container.manager-grid{
    max-height: 100vh;
    overflow-y: clip;
}

.eye-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -44%);
    display: flex;
    gap: 11px;
}

.eye {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.eye:after {
    /*pupil*/
    position: absolute;
    bottom: 12px;
    right: 10px;
    width: 7px;
    height: 7px;
    background: #000;
    border-radius: 50%;
    content: " ";
}

.header {
    display: flex;
    align-items: center;
    z-index: 100;
    padding: 20px;
    padding-left: 0;
    background-color: #fafafa1a;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    img {
        height: 50px;
    }

    .nav-links {
        display: flex;
        justify-content: end;
        gap: 20px;
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        align-items: center;
        letter-spacing: 1px;
        padding-right: 3vw;

        a {
            padding: 10px;
            position: relative;
            display: inline-block;
            color: #1A1A1A;
            overflow: hidden;
            background: linear-gradient(to right, #11686D, #11686D 50%, #1A1A1A 50%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200% 100%;
            background-position: 100%;
            transition: background-position 275ms ease;
            text-decoration: none;

            &:nth-last-child(4) {
                background: linear-gradient(to right, RED, RED 50%, #1A1A1A 50%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-size: 200% 100%;
                background-position: 100%;
            }

            &:hover {
                background-position: 0 100%;
                text-shadow: 1px 1px 3px #11686d29;
            }
        }
    }

    .active {
        background-position: 0 100% !important;
    }
}

.dark-mode {
    .header {
        background-color: black;
        box-shadow: 0px 1px 20px 0px rgb(255 255 255 / 17%);
    }

    .nav-links {
        a {
            padding: 10px;
            position: relative;
            display: inline-block;
            color: white;
            overflow: hidden;
            background: linear-gradient(to right, #11686D, #11686D 50%, white 50%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200% 100%;
            background-position: 100%;
            transition: background-position 275ms ease;
            text-decoration: none;

            &:nth-last-child(3) {
                background: linear-gradient(to right, RED, RED 50%, white 50%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-size: 200% 100%;
                background-position: 100%;
            }

            &:hover {
                background-position: 0 100%;
                text-shadow: 1px 1px 3px #11686d29;
            }
        }
    }

    .stats,
    .manager-container {
        background-color: black;
    }

    .info-name {
        color: white;
    }
}

.ant-divider {
    font-family: var(--font);
    margin: 0 0 10px 0 !important;
    color: #000000a8 !important;
    font-size: 12px !important;
}

.stats {
    h3 {
        font-family: var(--font);
    }

    .ant-statistic-title, .ant-statistic-content, .ant-card-body>div {
        font-family: var(--font) !important;
        font-weight: 500 !important;
    }

    .ant-divider-horizontal {
        border-color: rgba(0, 0, 0, 0.594);
    }
}

.noti-container p {
    width: 300px;
    margin: auto;
    font-size: 12px;
    font-weight: 400;
    transition: background-color 275ms ease;
    border-radius: 5px;
    cursor: pointer;
    font-family: var(--font);

    &:hover {
        background-color: rgb(239, 239, 239);
    }
}

.announcement-modal .ant-modal-content {
    background-color: transparent;
    box-shadow: none;

    .anticon-close {
        color: black;
    }
}

.noti-container::-webkit-scrollbar-button {
    display: none !important;
}

.noti-container::-webkit-scrollbar {
    width: 10px;
    margin-left: 5px;
}

.noti-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.227);
    border-radius: 4px;
}

a.logout {
    &:hover {
        color: red;
    }
}

.no-notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font);

    img { 
        width: 80px !important;
        margin: 0 !important;
    }

    h5 {
        margin-top: 5px;
        font-family: var(--font);
    }

    button {
        font-family: var(--font);
        color: dodgerblue;
        background-color: rgba(30, 143, 255, 0.239);
        margin-top: 10px;

        &:hover, &:active {
            color: white !important;
            background-color: rgba(30, 143, 255) !important;
        }
    }
}

.noti-container {
    max-height: 350px;
    overflow: auto;
    padding-right: 5px;

    a {
        text-decoration: none;
        font-size: 12px;

        &:hover {
            text-decoration: underline;
        }
    }

    .notification {
        display: flex;
        gap: 10px;
        align-items: start;
        margin-bottom: 10px;
        padding-bottom: 10px;
        box-shadow: 0px 0.6px 0px 0px rgb(0 0 0 / 10%);
    }

    img {
        width: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .logo-container {
        padding: 5px;
        border-radius: 50%;
        background-color: #e13f3f;
        color: white;
        margin-right: 5px;
        width: 32px;
        text-align: center;
    }
}

.dropdown-link {
    text-decoration: none;
    letter-spacing: 1px;
}

.actions {
    a {
        width: 200px;
        color: black;
        text-decoration: none;
        font-size: 16px;
        font-family: var(--font);

        &:hover {
            text-decoration: underline;
        }
    }
}

.logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20%;
    opacity: 0;
    transition: opacity 3s ease;
}

.logo-image.loaded {
    opacity: 1;
}

.info-container {
    position: absolute;
    bottom: 3%;
    right: 3%;
    padding-right: 3%;
    text-align: right;
    font-size: 50px;
}

.info-name {
    font-weight: 700;
    letter-spacing: 2px;
    color: #1A1A1A;
    margin: 0;
}

.info-date {
    font-size: 50%;
    font-weight: 700;
    color: #11686D;
    margin: 0;
}

.bulb-container {
    position: absolute;
    bottom: 3%;
    left: 3%;
    width: 100px;
    overflow: hidden;

    img {
        /* transition: transform 1.5s ease-out; */
        cursor: pointer;
    }
}

/* .dark-mode img {
    transform: translateX(-50%);
} */

@media (max-width: 768px) {
    .logo-image {
        height: 10%;
    }

    .info-container {
        font-size: 30px;
    }
}

@media (max-width: 500px) {
    .info-container {
        font-size: 20px;
    }

    .sider {
        width: 200px !important;
        max-width: 200px;
        min-width: 200px;

    }
}

.sider {
    height: 70vh;
    top: 15vh;
    border-radius: 30px;
    margin-left: 3vh;
    z-index: 100;


}

.side-icon {
    font-size: 20px;
}

.ant-layout-sider-trigger {
    top: 78vh;
    border-radius: 30px;
}

.option {
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 100;
    padding-left: 5vh;
}

.option:hover {
    text-decoration: none;
}

.settings-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
    width: 400px;
    /* height: 300px; */
    padding: 20px;
    font-family: var(--font);

    h5 {
        letter-spacing: 1px;
    }

    .ant-statistic-title {
        font-size: 12px;
    }

    .ant-statistic-content-value {
        font-size: 14px;
    }

    .ant-card-body {
        padding: 8px;
    }

    .settings-icon {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        color: gray;
        overflow: hidden;
        box-shadow: 0 0 10px lightgray;
        transition: box-shadow 0.3s ease;

        .icon {
            z-index: 1;
            transition: 0.5s;
        }

        .overlay {
            position: absolute;
            opacity: 0;
            transition: 0.5s;
        }

        &:hover {
            box-shadow: 0 0 10px rgba(173, 162, 162, 0.724);

            .overlay {
                opacity: 1;
                z-index: 2;
            }

            .icon {
                opacity: 0;
            }
        }

        img {
            max-height: 150px;
            max-width: 150px;
            object-fit: contain;
        }
    }

    .buttons-container {
        display: flex;
        margin-top: 20px;
        width: 100%;
        background-color: #d3d3d373;
        border-radius: 25px;

        div {
            width: 50%;
            padding: 10px;
            transition: box-shadow 0.3s ease;
            cursor: pointer;
        }

        div:last-child {
            border-radius: 0 25px 25px 0;
            border-left: 1px solid rgb(115, 115, 115);

            span {
                margin-left: 10px;
            }

            &:hover {
                box-shadow: 1px 4px 10px rgb(66 66 66 / 45%);
            }
        }

        div:first-child {
            border-radius: 25px 0 0 25px;
            text-align: right;
            border-right: 1px solid rgb(115, 115, 115);

            span {
                margin-right: 10px;
            }

            &:hover {
                box-shadow: -1px 4px 10px rgb(66 66 66 / 45%);
            }
        }

    }

}

.home-container {
    display: flex;
    height: 40%;
    gap: 50px;
    
    > div:first-child {
        background-color: white;
        min-height: 250px;
        width: 350px;
        height: 100%;
        border-radius: 15px;
        box-shadow: 0px 4px 4px 0px #00000040;

        font-family: var(--lexend);
        h5 {
            font-weight: 300;
            letter-spacing: 1.5px;
            font-size: 25px;
        }

        h4 {
            font-weight: 200;
            font-size: 22px;
            margin-top: 20px;
        }

        h2 {
            font-size: 25px;
            font-weight: 400;
            letter-spacing: 1px;
            color: #252C58;
            margin-top: 40px;
            text-align: left;
        }
    }

    .overlay {
        padding: 20px;
        position: absolute;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        img {
            opacity: 0.17;
            height: 100%;
        }
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        gap: 50px;
        
        .stat-card {
            width: calc(33% - 32px);
            height: calc(50% - 25px);
            background-color: white;
            transition: all 0.5s ease;
            border-radius: 15px;
            box-shadow: 0px 4px 4px 0px #00000040;
            display: flex;
            align-items: start;
            justify-content: space-between;
            padding: 10px 20px;
            cursor: pointer;
            text-decoration: none;
            color: black;
            
            h3 {
                font-family: var(--lexend);
                font-weight: 300;
                font-size: 26px;
                text-align: left;
                margin-bottom: 20px;
            }

            h4 {
                font-family: var(--lexend);
                font-weight: 300;
                font-size: 17px;
                text-align: left;
            }

            .icon-container {
                width: 50px;
                padding: 13px;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                text-align: center;
                background-color: #DAEEF0;
                justify-content: center;
                display: flex;

                img {
                    height: 100%;
                }
            }

            &:hover {
                box-shadow: 0px 4px 4px 1px #00000040;
                transform: scale(1.05);
            }
        }
    }
}

.graph-container {
    display: flex;
    height: 40%;
    height: 360px;
    gap: 50px;
    margin-top: 50px;

    > div {
        flex: 1;
        background-color: white;
        border-radius: 15px;
        box-shadow: 0px 4px 4px 0px #00000040;
    }

    .worldmap__figure-container {
        margin: 0;
        border-radius: 15px;
    }

    h3 {
        font-family: var(--lexend);
        font-weight: 300;
        font-size: 26px;
        text-align: left;
    }

    .MuiChartsAxis-tickLabel {
        font-size: 10px !important;
        font-weight: 300 !important;
        font-family: var(--lexend) !important;
    }
}

.remove-icon {
    transition: all 0.5s ease;

    &:hover {
        cursor: pointer;
        color: red;
    }
}

.accomodation-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    > div {
        position: relative;
        width: 48%;
        height: 300px;
        box-shadow: 1px 1px 5px 0px #00000063;
        border-radius: 15px;
        padding: 10px;

        h5 {
            font-family: var(--font);
            font-weight: 600;
        }
        p {
            font-family: var(--font);
            font-weight: 400;
            font-size: 12px;
        }

        .hidden-room {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: white;
            z-index: 100;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            font-family: var(--font);
        }
    }
    
    .headers {
        div {
            width: 15%;
        }
        p {
            font-weight: 700;
        }
    }

    .details {
        overflow: auto;
        height: 170px;
        
        > div {
            display: flex;
            /* justify-content: space-between; */
            font-family: var(--font);
            font-size: 12px;
            div {
                width: 15%;
                overflow: hidden;
                white-space: nowrap;
            }
        }

    }
}

.shortcuts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;

    > div {
        display: flex;
        gap: 10px;
        font-size: 15px;
        align-items: center;

        span {
            border-radius: 50%;
            background-color: #DAEEF0;
            width: 20px;
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a {
            text-decoration: none;
            color: black;
        }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}