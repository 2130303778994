.create-vehicle {
    .ant-form-item-explain-error {
        font-family: var(--font);
        font-size: 11px;
    }
}

.vehicle-details {
    overflow-x: hidden;
    display: flex;
    padding: 0 5%;
    padding-top: 20px;

    .ant-select {
        margin: 0;
    }

    .ant-input {
        width: 300px;
        border: none;
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0;
        font-size: 16px;

        &:focus {
            box-shadow: none;
        }
    }

    > div {
        flex: 1;

        div {
            display: flex;
            margin-bottom: 15px;
        }
    }

    h3 {
        width: 200px;
        font-size: 16px;
        text-align: left;
    }
    p {
        margin: 0;
    }

    .timeline {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        height: 80vh;
    }
    
    .timeline::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px; /* Adjust height of the smoky effect */
        pointer-events: none;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1)); /* Smoky effect */
        z-index: 1;
    }

    .timeline > * {
        position: relative;
        z-index: 0;
    }
}