/* .hr-forms{
    margin-top: 15vh;
    margin-left: 6vw;
    margin-right: 6vw;
} */

.pagination-container {
    display: flex;
    justify-content: center; /* Center pagination */
    margin-top: 20px;
    margin-bottom: 20px;
}

/* .img-container {
    height: 250px;
    overflow: hidden;
} */

.ant-card-cover {
    width: 100%;
}

.hr-forms .card {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    height: auto; /* Allow height to adjust based on content */
    padding: 20px; /* Add padding to prevent text touching the borders */
    text-align: center;
}

.hr-forms .card.even {
    background-color: #13777d;
    color: white;
}

.hr-forms .card.odd {
    background-color: #F5F5F5;
    color: #2db2b9;
}

.home-header {
    top: 40px;
    left: 20px;
}

.home-link {
    color: #11686D;
    /* paddingRight: 3vw; */
}

.form-link {
    font-family: var(--font);
    color: inherit; /* Ensures text color matches the card */
    word-wrap: break-word; /* Allows long words to break and wrap to the next line */
    display: inline-block; /* Changes display to handle wrapping */
    max-width: 100%; /* Limits the width to prevent overflow */
    margin: 0 auto; /* Centers the link */
    text-decoration: none;
    font-size: 15px;
    transition: letter-spacing 0.3s ease;

    &:hover {
        text-decoration: none;
    }
}

.green-shadow:hover {
    box-shadow: 0 1px 2px -2px rgba(19, 119, 125, 0.16), 0 3px 6px 0 rgba(19, 119, 125, 0.12), 0 5px 12px 4px rgba(19, 119, 125, 0.09);
}

.card img {
    width: 100%;
    transition: transform 0.3s ease;
  }
  
  .card:hover img {
    transform: scale(1.05); /* Optional: Zoom in effect on hover */
  }

  .card:hover .form-link {
    letter-spacing: 1px;
  }

/* Additional style for tooltip to ensure consistency */
.ant-tooltip-inner {
    max-width: 250px; /* Adjust based on your preference */
    word-wrap: break-word;
}

textarea.ant-input {
    border: none;
    text-align: center;
}
