.salaries-payroll{
  margin-top: 15vh;
  margin-left: 10vw;
  margin-right: 10vw;
}


.table-container {
  max-width: 80vw;
  overflow-x: auto; 
}

/* .table-container th {
  background-color: #11686D;
  font-size: medium;
  font-weight: 500;
  text-align: center;
  height: 50px;
  color: whitesmoke;
  border-radius: 30px;
}

.table-container tr{
  padding: 10px 0;
} */

/* .table-container table {
  border-radius: 30px;
  background-color: #11686D;
} */

.table-container .ant-input {
  border-radius: 0px;
  border: 1px solid #e8e6e69c;
  padding: 8px;
}

/* .blur-right {
  position: absolute;
  top: -10px;
  right: 9vw;
  bottom: 0;
  width: 15vw;
  background: linear-gradient(to right, #f5f5f500, var(--background));
  pointer-events: none;
} */

.salaries-payroll .ant-statistic-title {
  font-size: 13px !important;
}