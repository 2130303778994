.savedfiles-list{
    color: #337ab7;
    text-decoration: none;
    margin-left: 2vw;
}
body{
    background-color: var(--background);
    font-family: var(--font);
}

.saved-files {
    margin-top: 15vh;
    margin-left: 8vw;
}