.employee-container {
    height: 100vh;
    margin: 0 auto;
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-picker-input, .ant-input-number-input, .ant-select-selector {
    font-family: var(--font) !important;
}

.ant-col {
    width: 23vw;
}

.submit-button {
    /* width: 40vw; */
    /* left: 15vw; */
    width: 100%;
    height: 38px;
    font-family: var(--font);
    letter-spacing: 0.3vw;
    background-color: #11686D;
}

.submit-button:hover, .submit-button:focus {
    background-color: #2db2b9 !important;
}