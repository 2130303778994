.body {
  display: flex;
  background-color: var(--background);
  padding: 5vh;
  font-family: var(--font);
  position: relative;
  min-height: 100vh;
  /* min-width: 100vw; */
  height: max-content;
}

.dashboard { 
  margin: 0 auto;
  width: 90%;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
}

.ant-table-column-sorter-inner  span.active {
  color: white !important;
}

.ant-table-row-expand-icon-cell {
  display: none;
}

.employee-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ant-table-row:hover .employee-delete {
  opacity: 1;
}

.table-wrapper {
  overflow: hidden;
  .table {
    width: 100%;
    transition: width 0.5s ease-in-out;
  }

  .filter-container {
    /* background-color: red; */
    padding: 30px;
    width: 0;
    overflow: hidden;
    transition: width .5s ease-in-out;
  }
}

.employee-shortcuts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  a {
    color: black;
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  }
}


.filter-container > div {
  width: 300px;
}

.filter-container  p {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1px;
}

.table-wrapper-open {
  overflow: hidden;
  .table {
    width: 70%;
    transition: width 1s ease-in-out;
  }
  .filter-container {
    padding: 30px;
    width: 30%;
    transition: width 1s ease-in-out;
    /* background-color: red; */
    overflow: hidden;
  }
}

.ant-input, .ant-select-selection-item, .ant-table-cell{
  font-family: var(--font);
  /* font-size: 15px; */
}

@media (max-width:768px) {
  .ant-table-cell{
    font-size: 10px;
    white-space: normal !important;
  }

  .ant-input, .ant-select-selection-item, .ant-select-selector {
    font-size: 10px;
  }
}

@media (max-width:500px) {
  .dashboard h3{
    padding-top: 1vh;
    font-size: 17px;
  }

  .dashboard {
    padding: 0;
  }

  .body {
    padding: 2vh 0;
  }
  .input-fields {
    padding-bottom: 2vh;
  }
}

.home-logo {
  position: absolute;
  top: 3%;
  left: 3%;
  font-size: 5vh;
  color: #11686D;
}


.smalllogo-image {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 5vh;
}

.input-fields {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5vh;
}

.even-row {
  background-color: #FFF !important;
}

.cell-cutoff {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.even-row:hover td{
  background-color: #8fb7ba !important;
}

/* .ant-table {
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
} */

.ant-table-content table{
  width: max-content;
}

.ant-checkbox-group-item {
  font-family: var(--font);
  font-weight: 300;
  padding-right: 5px;
}

.odd-row, .odd-row .ant-table-cell-fix-left{
  background-color: #11686D !important;
  color: #FFF !important;
}

.odd-row:hover td{
  background-color: #082f32 !important;
}

.odd-row a{
  color: whitesmoke;
}

.odd-row .ant-table-cell-fix-right {
  background-color: #11686d84 !important;
}

.odd-row .ant-table-cell {
  background-color: #11686D !important;
}
.ant-table-thead .ant-table-cell {
  /* position: sticky; */
  top: 0;
  /* z-index: 10; This ensures that the header remains above the content when scrolling */
  background-color: #4d4d4d !important;
   color: #FFF !important; /* To ensure the background isn't transparent when scrolling */
}

   
.ant-input-search .ant-input:focus{
  border-color: #11686D;
}

/* .ant-input-search-button {
  background-color: #11686D;
  color: white !important;
}

.ant-input-search-button:hover {
  background-color: #082f32;
} */

/* .ant-float-btn-body {
  background-color: #6FB555 !important;
} */
