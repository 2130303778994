/* General styles and theme */
.body {
    display: flex;
    background-color: var(--background);
    padding: 5vh;
    font-family: var(--font);
    position: relative;
    min-height: 100vh;
    height: max-content;
}

.dashboard {
    margin: 0 auto;
    width: 90%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Styling for select and input elements */
.ant-input, .ant-select-selection-item, .ant-table-cell {
    font-family: var(--font);
    /* font-size: 15px; */
}

/* Responsive design adjustments for smaller screens */
@media (max-width: 768px) {
    .ant-table-cell {
        font-size: 10px;
        white-space: normal !important;
    }

    .ant-input, .ant-select-selection-item, .ant-select-selector {
        font-size: 10px;
    }
}

@media (max-width: 500px) {
    .dashboard h3 {
        padding-top: 1vh;
        font-size: 17px;
    }

    .dashboard {
        padding: 0;
    }

    .body {
        padding: 2vh 0;
    }

    .input-fields {
        padding-bottom: 2vh;
    }
}

/* Additional styles for specific elements */
.home-logo {
    position: absolute;
    top: 3%;
    left: 3%;
    font-size: 5vh;
    color: #11686D;
}

.smalllogo-image {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 5vh;
}

.input-fields {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5vh;
}

/* Table row styling */
.even-row {
    background-color: #FFF !important;
}

.even-row:hover td {
    background-color: #8fb7ba !important;
}

.odd-row, .odd-row .ant-table-cell-fix-left {
    background-color: #11686D !important;
    color: #FFF !important;
}

.odd-row:hover td {
    background-color: #082f32 !important;
}

.odd-row a {
    color: whitesmoke;
}

.odd-row .ant-table-cell-fix-right {
    background-color: #11686d84 !important;
}

.odd-row .ant-table-cell {
    background-color: #11686D !important;
}

/* Styling for table header */
.ant-table-thead .ant-table-cell {
    /* position: sticky; */
    top: 0;
    /* z-index: 10; */
    background-color: #4d4d4d !important;
    color: #FFF !important;
}

/* Search input styling */
.ant-input-search .ant-input:focus {
    border-color: #11686D;
}

/* .ant-input-search-button {
    background-color: #11686D;
}

.ant-input-search-button:hover {
    background-color: #082f32;
} */

/* .ant-float-btn-body {
    background-color: #6FB555 !important;
} */

/* Additional custom styles for your component */
.controls {
    padding: 2vh 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-select {
    width: 30%;
    margin-right: 1vw;
}

.dashboard-button {
    background-color: #6FB555;
    color: white;
    border: none;
}

.dashboard-button:hover {
    background-color: #082f32;
    border-color: #082f32;
}

.table-container {
    width: 100%;
    margin-top: 1rem;
}
.dashboard-banner {
    display: flex;
    justify-content: center; /* Centers the cells horizontally */
    margin-bottom: 20px;
}

.dashboard-banner-cell {
    display: flex; /* Turns the cell into a flex container */
    flex-direction: column; /* Stacks items vertically */
    justify-content: center; /* Centers items vertically */
    align-items: center; /* Centers items horizontally */
    
    flex: 1;
    padding: 15px;
    margin: 0 10px;
    background-color: #f2f2f2; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-banner-cell .cell-label {
    font-size: 15px;
    /* position:absolute; */
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.dashboard-banner-cell .cell-content {
    font-size: 20px;
    color: #11686D;
}
