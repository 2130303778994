/* .payroll-type{
  margin-top: 17vh;
  margin-bottom: 20px;
  margin-left: 5vw;
} */

.payroll-type select{
  background-color:#13777d;
  color: white;
  padding: 3px 10px;
  border-radius: 15px;
  font-size: large;
}

.custombutton{
  background-color:#178a90;
  color: white;
  padding: 1vh 2vw;
  border-radius: 5px;
  margin-bottom: 40px;
  border: none;
  /* margin-left: 5vw; */
  margin-right: 20px;
  font-size: medium;
  border-radius: 15px;
}

.payroll-department{
  /* margin-left: 8vw; */
  font-size: large;
}

.primary-button {
  background-color: #5dcc4c;
  color: white;
  padding: 3px 30px;
  border: white;
  border-radius: 5px;
  position: fixed; 
  bottom: 40px; 
  font-size: large;
  /* left: 8vw; */
}

.inputdate input{
  border: none;
  border-radius: 5px;
  margin-right: 20px;
  font-size: large;
  padding: 5px;
}

.inputdate{
  font-size: large;
  /* margin-left: 8vw; */
}

.existing-setups-container {
  position: absolute;
  right: 10vw;
  top: 30vh;
  padding: 1rem;
  min-width: 35vw;
}

.existing-setups-container h3 {
  text-align: left;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.custom-label-only .ant-form-item-control {
  display: none;
  font-weight: bold;
}


.existing-setups-container ul {
  list-style-type: none;
  padding-left: 0;
}

.existing-setups-container ul li {
  font-size: 1rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
