.disciplinary-action-form{
  height: max-content;
  min-height: 100vh;
  background-color: var(--background);
  font-family: var(--font);
  padding: 20px 200px;
}

.disciplinary-action-form h3{
  margin: 0 auto;
  margin-bottom: 5vh;
  border: 1px solid black;
  padding: 1vh 0;
}

.form-table {
  width: 100%;
  margin: auto;
}

.form-table td {
  border: 1px solid black;
  /* height: 5vh; */
}

.form-table label {
  font-weight: 400;
  padding-left: 2vw;
}

.form-table input {
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
}

.checkbox-table{
  width: 100%;
  margin: auto;
}

.checkbox-table input{
  margin-right: 20px;
}

.radio-table {
  width: 100%;
}

.radio-table input{
  margin-right: 20px;
}

.form-group {
  width: max-content;
}

.form-group input{
  border: none;
  border-bottom: 1px solid black;
  background-color: transparent;
  width: 100%;
  text-align: center;
}

.textarea-group {
  display: flex;
}

.textarea {
  display: block;
  min-width: 10%;
  width: max-content;
  max-width: 70%;
  margin-left: 20px;
  overflow:visible;
  resize: vertical;
  line-height: 20px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  font-weight: 300;
  padding: 1px 6px;
  
}

.radio-table td, .checkbox-table td {
  font-size: 12px;
}

.local-leave-container {
  textarea {
    font-size: 14px;
  }

  .form-table label {
    font-size: 14px;
    padding: 0;
  }

  .form-table td {
    text-align: center;
  }
}

.textarea-group textarea{
  margin-left: 20px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}