.details-container {
    font-family: var(--font);
    min-height: 100vh;
    height: fit-content;
    margin: 0 auto;
    background-color: var(--background);
    display: flex;
    padding: 10vh 0;
    justify-content: center;
}

.details-container h2{
    padding-top: 10vh;
    padding-bottom: 5vh;
    font-weight: 700;
}

.personal-files-container {
    flex: 1;
    border-right: 1px solid #ccc;
    padding: 10px;
    position: relative;

    .ant-card {
        background-color: rgb(235, 235, 235);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        position: relative;

        .file-buttons {
            display: none;
        }

        h5 {
            font-family: var(--font);
            font-weight: 400;
            font-size: 15px;
            text-align: center;
        }

        &:hover {
            h5 {
                display: none;
            }
            .file-buttons {
                display: flex;
                gap: 20px;
            }
        }
    }
}

.details-container h3{
    padding: 5vh 0;
    font-weight: 600;
}

.status-select-green .ant-select-selection-item {
    color: #10ca10;
    font-weight: 700;
}

.status-select-red .ant-select-selection-item {
    color: red;
    font-weight: 700;
}

.status-select-orange .ant-select-selection-item {
    color: orange;
    font-weight: 700;
}

.button-container {
    width: 98vw;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
}

.details-search .ant-btn{
    height: 29px;

    svg {
        width: 12px;
    }
}

h2, h3 {
    text-align: center;
  }

.button-container button{
    margin: 1vw 1vw;
    font-family: var(--font);
    width: 300px;
    letter-spacing: 1px;
    background-color: #11686D;
}

.button-container button:hover, .button-container button:focus{
    background-color: #2db2b9 !important;
}

.ant-timeline-item-content {
    font-family: var(--font);
    font-weight: 300;
}

input#file-upload-button {
    font-family: var(--font);
}

.custom-file-input-button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    font-family: 'YourFontFamily';
    padding: 10px 20px; /* Adjust padding as needed */
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
 }
 
 /* Style on hover (optional) */
 .custom-file-input-button:hover {
    background-color: #0056b3; /* Change color on hover */
 }

 .image-container {
    max-width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50px;
    margin-bottom: 10px;
 }

 .detail-fields {
    padding-left: 5vw;
 }

 .ant-form-item-label label{
    font-family: var(--font);
    font-weight: 300;
    letter-spacing: 1.5px;
 }

 .image-row {
    width: 15vw;
 }

 .detail-fields input{
    border: none;
    background-color: #e0e1e2;
    border-bottom: 1px solid #00000066;
    font-weight: 300;
 }

 @media (max-width:992px) {
    .image-container {
        width: 50vw;
    }

    .button-container {
        position: relative;
        padding-bottom: 2vh;
    }
    .details-container h2{
        padding-top: 5vh;
    }
    .image-row{
        padding-left: 20vw;
        width: 60vw;
     }
    .detail-fields {
        padding-left: 0;
     }
 }

.create-button {
    /* padding: 0 2vw; */
    width: 100%;
    margin-top: 22px;
    letter-spacing: 1.5px;
    font-family: var(--font);
    background-color: #11686D;
    border: none;
}

.create-button:hover {
    background-color: #218d92 !important;
}

.placeholder-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    /* width: 15vw;
    height: 30vh; */
    background-color: lightgray;
    border-radius: 50px;
    /* margin-bottom: 10px; */
    margin-right: 20px;
    max-width: 170px;
    max-height: 230px;
}

.emp-details-container {
    .ant-input, .ant-select-selector, .ant-picker, .ant-picker-input input {
        font-size: 12px;
        border-radius: 20px;
    }

    .delete-button {
        opacity: 0;
        position: absolute;
        right: 0;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        &:hover {
            background-color: red;
            color: white;
        }
    }

    .profile-container {
        position: relative;

        &:hover {
            .delete-button {
                opacity: 1;
            }
        }
    }


    label {
        font-size: 12px !important;
        margin: 0;
        opacity: 0.6;
    }

    .ant-form-item {
        margin-bottom: 5px;
    }

    .ant-form-item-label {
        padding: 0;
        padding-top: calc((100vh - 660px) / 15);
    }
}

.employee-sidebar {
    width: 60px;
    min-width: 60px;
    box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    font-size: 25px;
    padding: 20px 0;

    > div {
        cursor: pointer;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: rgba(128, 128, 128, 0.427);
        }
    }

    > div.active {
        background-color: #11686D;
        color: white;
    }
}