/* App.css */

/* Add more CSS styles here */
.hide-resize-observer {
  display: none !important;
}

:root {
  --font: Montserrat;
  --lexend: 'Lexend', sans-serif;
  --background: white;
}
