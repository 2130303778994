.analysis-container {
    background-color: var(--background);
    height: 100vh;
    width: 100vw;
    font-family: var(--font);
    position: relative;
}

.home-header {
    position: absolute;
    top: 5%;
    left: 5%;
    font-size: 5vh;
    color: #000;
    font-weight: 700;
  }

  .company-selector {
    bottom:5vh;
  }

  .grid-cell {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px 30px 30px 30px;
    margin: 10px;
    text-align: center;
    border-radius: 30px;
    background-color: #7e96989c;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: #7e9698;
    }
}

.cell-label {
    text-align: left;
    font-size: 15px;
    font-weight: 300;
}

.recharts-cartesian-axis-tick {
    display: none;
}

.cell-content {
    font-size: 40px;
    font-weight: 700;
}

.recharts-legend-item{
    font-size: 1.2vw;
}

.bar-chart{
    width: 500px;
}

.pie-chart{
    width: 500px;
    padding-left: 5vw;
}

@media (max-height:682px) {
    .bar-chart, .pie-chart {
        width: 400px;
    }
}

@media (max-height:532px) {
    .bar-chart, .pie-chart {
        width: 300px;
    }
}

@media (max-width:992px) {
    .analysis-counts {
        padding-top: 15vh;
    }

    .company-selector {
        padding-top: 10vh;
    }

    .analysis-container {
        height: max-content;
    }

    .cell-content {
        font-size: 25px;
    }

    .cell-label {
        font-size: 12px;
        position: relative;
        top: 0;
        left: 0;
    }

    .grid-cell {
        padding: 5vh 0;
    }
}
/* Adjust the font size of selected items in the Ant Design Select component */
.ant-select-selection-selected-value {
    font-size: 0.5rem !important;  /* adjust the value as needed */
}
