/* ManagerAdmins.css */

.departments-list {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e8e8e8;
    background-color: #f7f7f7;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .departments-list h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .departments-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .departments-table th {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #e8e8e8;
  }
  
  .departments-table td {
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
  }
  
  .company-card {
    display: flex;
    justify-content: space-between;
  
    button {
      /* display: none; */
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    p {
      font-family: var(--font);
      letter-spacing: 1px;
    }
  }

  .company-card-container .ant-card-body {
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.16);
  }

  .company-card-container .ant-card-body:hover {

    button {
      /* display: block; */
      opacity: 1;
    }
  }
