.body {
    display: flex;
    padding: 5vh;
    position: relative;
    min-height: 100vh;
    height: max-content;
  }
  
  .analysis-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: var(--background);
      font-family: var(--font);
    }
  
    .analysis-link {
        color: #fff;
        text-decoration: none;
        display: flex;
        justify-content: center; /* Horizontal centering */
        align-items: center;     /* Vertical centering */
        width: 100%;             /* Take full width of parent */
        height: 100%;            /* Take full height of parent */
    }
    
    .analysis-link:hover{
      text-decoration: none;
      background-color:#13777d;
      color: white;
    }
  
    .analysis-item:hover{
      text-decoration: none;
      background-color:#13777d ;
      color: white;
    }
  
    .analysis-item{
      background-color:  #11686D ;
      border-radius: 12px;
      padding: 5px 5px;
      text-decoration: none;
      font-size: 25px;
      width: 400px;
      list-style-type: none;
      text-align: center;
      margin-bottom:20px;
    }
    
  
    
  .analysis-link svg {
    margin-right: 70px;
    margin-left: 10px;
  }