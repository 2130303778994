.leave-application-form {
    padding: 20px 60px;
}

.section-header {
    width: 100%;
}

.section-header td{
    border: 1px solid black;
    text-align: center;
}

.leave-table {
    width: 100%;
}

.leave-table h5{
    font-weight: 700;
    padding: 0 10px;
}

.leave-table tr, .leave-table td{
    border: 1px solid black;
}

.leave-table label{
    padding: 0px 10px;
    font-weight: 500;
    margin-bottom: 2px;
}

.leave-table input{
    width: 100%;
    height: 25px;
    border: none;
    text-align: center;
}
.leave-table h5{
    margin: 5px 0;
}

.ant-radio-wrapper{
    font-family: var(--font);
    font-weight: 700;
}

.date-fields td{
    text-align: center;
    width: 25%;
}

.leave-table5 {
    width: 100%;
}

.leave-table5 td {
    border: 1px solid black;
    width: 20%;
    text-align: center;
    font-size: smaller;
    font-weight: 500;
}

.leave-table5 input[type = "text"] {
    width: 100%;
    height: 25px;
    text-align: center;
    border: none;
}

.leave-table5 input[type = "radio"] {
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.leave-table5 .ant-picker-input input{
    text-align: center;
}

.leave-table5 textarea{
    width: 100%;
    border: none;
    resize: none;
    height: 40px;
}

.header-row {
    border-width: 3px 0 !important;
}

.leave-checkbox label{
font-weight: 500;
}
.leave-checkbox input{
margin-right: 20px;
}

.training-row input{
    height: 20px;
}

.training-row textarea{
    width: 120px;
    resize: none;
    height: 50px;
    border: none;
}

.training-requirement label{
    padding: 8px 3px;
}